













































import { defineComponent, ref, watch } from '@vue/composition-api';
import ScatterChartComponent from '@/components/EmotionAnalysis/organisms/ScatterChart.vue';
import { BasicEmotionItemType, basicEmotionValues } from '@/types/emotion-analysis';
import { useScatterChartData } from '@/composables/store/EmotionAnalysis/useScatterChartData';
import { useTime } from '@/composables/function/Common/useTime';
import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';

export default defineComponent({
    components: {
        ScatterChart: ScatterChartComponent,
    },
    setup() {
        const openPanel: { value: number[] | null } = ref(null);
        const label = ref(false);
        const { openResultPanels, setOpenResultPanelsLocalStorage, axisRange, maxDurationOfCall, initAxisRange } =
            useScatterChartData();
        const { convertToMinutesFormat } = useTime();
        const { selectedEmotionAnalysis, isSelected } = useAnalysisListSelect();

        watch(selectedEmotionAnalysis, () => {
            if (isSelected()) {
                openPanel.value = openResultPanels.value;
                //NOTE: 選択リストの内の最大通話時間をX軸とする
                initAxisRange();
                return;
            }
            // NOTE: 対象データが選択されていない場合はパネルを全て閉じる
            openPanel.value = null;
        });

        watch(openPanel, () => {
            // NOTE: ユーザーが手動で閉じた場合(length === 0)は更新
            if (!openPanel.value) return;
            setOpenResultPanelsLocalStorage(openPanel.value);
        });

        // HACK:表示に関する事はSFC内に一旦置く。要検討
        const colorType = (type: BasicEmotionItemType): string => {
            if (type === 'pos') return 'red--text';
            if (type === 'neg') return 'blue--text';
            return 'black--text';
        };

        return {
            openPanel,
            label,
            isSelected,
            basicEmotionValues,
            colorType,
            axisRange,
            maxDurationOfCall,
            convertToMinutesFormat,
        };
    },
});
