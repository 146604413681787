






import { defineComponent } from '@vue/composition-api';
import { useSearchDialog } from '@/composables/store/EmotionAnalysis/useSearchDialog';

export default defineComponent({
    setup() {
        const { openSearchDialog, fetchCallStatusList } = useSearchDialog();

        const open = (): void => {
            fetchCallStatusList();
            openSearchDialog();
        };

        return {
            open,
        };
    },
});
