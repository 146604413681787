import { isFailedResponse } from '@/models/interface/common';
import { CallStatusInfo, TenantSchemaInfo } from '@/models/interface/EmotionAnalysis/ITenantARepository';
import { FetchCallStatusListService } from '@/models/service/EmotionAnalysis/FetchCallStatusListService';
import { FetchTenantSchemaListService } from '@/models/service/EmotionAnalysis/FetchTenantSchemaListService';
import { ErrorStatus } from '@/types/common';
import { SearchDialogForm } from '@/types/emotion-analysis';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';

const fetchSchemaListService = container.resolve(FetchTenantSchemaListService);
const fetchCallStatusListService = container.resolve(FetchCallStatusListService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideSearchDialog = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const searchDialogFlag = ref(false);

    const schemaList = ref<TenantSchemaInfo[]>([]);

    const callStatusList = ref<CallStatusInfo[]>([]);

    const localStorageTargetTenant =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        localStorage.getItem('targetTenant') !== null ? JSON.parse(localStorage.getItem('targetTenant')!) : undefined;
    const targetTenant = ref<TenantSchemaInfo | undefined>(localStorageTargetTenant);

    const searchDialogForm = ref<SearchDialogForm>({
        opName: '',
        statusList: [],
    });

    provide('error', error);
    provide('searchDialogFlag', searchDialogFlag);
    provide('schemaList', schemaList);
    provide('callStatusList', callStatusList);
    provide('targetTenant', targetTenant);
    provide('searchDialogForm', searchDialogForm);
    provide('localStorageTargetTenant', localStorageTargetTenant);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useSearchDialog = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const searchDialogFlag = inject('searchDialogFlag') as Ref<boolean>;
    const searchDialogForm = inject('searchDialogForm') as Ref<SearchDialogForm>;
    const schemaList = inject('schemaList') as Ref<TenantSchemaInfo[]>;
    const callStatusList = inject('callStatusList') as Ref<CallStatusInfo[]>;
    const targetTenant = inject('targetTenant') as Ref<TenantSchemaInfo | undefined>;
    const localStorageTargetTenant = inject('localStorageTargetTenant') as TenantSchemaInfo | undefined;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const initSearchDialogForm = (): void => {
        searchDialogForm.value.opName = '';
        searchDialogForm.value.statusList = [];
    };

    const setTargetTenant = (targetTenant: TenantSchemaInfo | undefined): void => {
        if (targetTenant === undefined) return;
        localStorage.setItem('targetTenant', JSON.stringify(targetTenant));
    };

    const openSearchDialog = (): void => {
        searchDialogFlag.value = true;
    };

    const closeSearchDialog = (): void => {
        // NOTE:検索項目を維持する為、searchDialogFormは初期化しない(テナント変更時はリセット)
        error.value.hasError = false;
        error.value.message = '';
        searchDialogFlag.value = false;
    };

    const fetchSchemaList = async (): Promise<void> => {
        const result = await fetchSchemaListService.handle();

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            return;
        }

        initStatus();
        schemaList.value = result.data.data;

        targetTenant.value = canAssignTenant(result.data.data) ? localStorageTargetTenant : result.data.data[0];
    };

    const fetchCallStatusList = async (): Promise<void> => {
        if (!targetTenant.value) return;
        const result = await fetchCallStatusListService.handle(targetTenant.value.identifier);

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            return;
        }
        initStatus();
        callStatusList.value = result.data.data;
    };

    const canAssignTenant = (tenants: TenantSchemaInfo[]): boolean => {
        return !!localStorageTargetTenant && isExistTenant(tenants, localStorageTargetTenant);
    };

    const isExistTenant = (tenants: TenantSchemaInfo[], target: TenantSchemaInfo): boolean => {
        return tenants.some(tenant => tenant.identifier === target.identifier);
    };

    return {
        error,
        searchDialogFlag,
        schemaList,
        callStatusList,
        targetTenant,
        searchDialogForm,
        initSearchDialogForm,
        openSearchDialog,
        closeSearchDialog,
        fetchSchemaList,
        fetchCallStatusList,
        setTargetTenant,
    };
};
