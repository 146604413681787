import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { AnalysisFetchListService } from '@/models/service/EmotionAnalysis/AnalysisFetchListService';
import { isFailedResponse } from '@/models/interface/common';
import { ErrorStatus } from '@/types/common';
import { usePagination } from '../Common/usePagination';
import { EmotionAnalysisInfo } from '@/models/interface/EmotionAnalysis/IEmotionAnalysisRepository';
import { TenantSchemaInfo } from '@/models/interface/EmotionAnalysis/ITenantARepository';
import { useSearchDialog } from './useSearchDialog';
import { useAnalysisListProgressCircular } from './useAnalysisLIstProgressCircular';

const analysisFetchIndex = container.resolve(AnalysisFetchListService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisList = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const emotionAnalysisList = ref<EmotionAnalysisInfo[]>([]);

    provide('error', error);
    provide('emotionAnalysisList', emotionAnalysisList);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisList = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const emotionAnalysisList = inject('emotionAnalysisList') as Ref<EmotionAnalysisInfo[]>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const { analysisListProgressStart, analysisListProgressEnd } = useAnalysisListProgressCircular();
    const { pageSize, pageNumber, setTotalPage } = usePagination();
    const { searchDialogForm } = useSearchDialog();

    const fetchList = async (targetTenant: TenantSchemaInfo | undefined): Promise<void> => {
        if (targetTenant === undefined) return;

        analysisListProgressStart();
        const response = await analysisFetchIndex.handle(
            targetTenant.identifier,
            pageSize.value,
            pageNumber.value,
            searchDialogForm.value.opName ?? undefined,
            searchDialogForm.value.statusList,
        );

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            analysisListProgressEnd();
            return;
        }

        initStatus();
        analysisListProgressEnd();
        setTotalPage(response.data.data.totalCount);
        emotionAnalysisList.value = response.data.data.analysisResultList;
    };

    return {
        error,
        emotionAnalysisList,
        fetchList,
    };
};
