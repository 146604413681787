import { ITenantARepository, TenantSchemaFetchResponse } from '@/models/interface/EmotionAnalysis/ITenantARepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class FetchTenantSchemaListService {
    constructor(@inject('ITenantARepository') private readonly repository: ITenantARepository) {}

    async handle(): Promise<TenantSchemaFetchResponse> {
        return this.repository.getTenantSchemaIndex();
    }
}
