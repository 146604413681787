










































import { defineComponent } from '@vue/composition-api';
import BasicEmotionCardComponent from '@/components/EmotionAnalysis/organisms/BasicEmotionCard.vue';
import SelectedRecordFileMenuComponent from '@/components/EmotionAnalysis/molecules/SelectedRecordFileMenu.vue';
import AudioControlPanelComponent from '@/components/EmotionAnalysis/organisms/AudioControlPanel.vue';
import { useScatterChartData } from '@/composables/store/EmotionAnalysis/useScatterChartData';
import { yAxisTypeValues } from '@/types/emotion-analysis';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';

export default defineComponent({
    components: {
        BasicEmotionCard: BasicEmotionCardComponent,
        SelectedRecordFileMenu: SelectedRecordFileMenuComponent,
        AudioControlPanel: AudioControlPanelComponent,
    },
    setup() {
        const { yAxisType, setYAxisTypeToLocalStorage } = useScatterChartData();
        const { isSelected } = useAnalysisListSelect();
        const { targetAudioData, audioElement, changeAudioCurrentTime, audioDataCurrentTime, playFlag } =
            useTargetAudioData();

        const setLocalStorage = (): void => {
            setYAxisTypeToLocalStorage();
        };

        return {
            isSelected,
            yAxisType,
            yAxisTypeValues,
            audioElement,
            targetAudioData,
            audioDataCurrentTime,
            changeAudioCurrentTime,
            playFlag,
            setLocalStorage,
        };
    },
});
