










import { useScatterChartData } from '@/composables/store/EmotionAnalysis/useScatterChartData';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';
import { defineComponent, watch, PropType } from '@vue/composition-api';
import { Chart, registerables } from 'chart.js';
import { ScatterChart } from 'vue-chart-3';
import { BasicEmotionInfo } from '@/types/emotion-analysis';

Chart.register(...registerables);

export default defineComponent({
    components: {
        ScatterChart,
    },
    props: {
        target: {
            type: Object as PropType<BasicEmotionInfo>,
            require: true,
        },
    },
    setup(props) {
        const { createChartData, createChartOptions, chartAudioPlugin } = useScatterChartData();
        const { selectedEmotionAnalysis } = useAnalysisListSelect();
        const { audioDataCurrentTime } = useTargetAudioData();
        const targetInfo = props.target;

        const getHeight = (): number => {
            // eslint-disable-next-line
            return targetInfo!.max > 30 ? 150 : 100;
        };

        watch(selectedEmotionAnalysis, () => {
            if (targetInfo === undefined) return;
            createChartData(targetInfo);
        });

        return {
            createChartData,
            createChartOptions,
            chartAudioPlugin,
            getHeight,
            targetInfo,
            audioDataCurrentTime,
        };
    },
});
