import { provide, ref, inject, Ref } from '@vue/composition-api';
import { ErrorStatus } from '@/types/common';
import { useAnalysisProgressList } from '@/composables/store/EmotionAnalysis/useAnalysisProgressList';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideConfirmProgressDialog = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const confirmProgressDialogFlag = ref(false);

    provide('error', error);
    provide('confirmProgressDialogFlag', confirmProgressDialogFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useConfirmProgressDialog = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const confirmProgressDialogFlag = inject('confirmProgressDialogFlag') as Ref<boolean>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const { fetchList } = useAnalysisProgressList();

    const openConfirmProgressDialog = (): void => {
        confirmProgressDialogFlag.value = true;
        fetchList();
    };

    const closeConfirmProgressDialog = (): void => {
        initStatus();
        confirmProgressDialogFlag.value = false;
    };

    return {
        error,
        confirmProgressDialogFlag,
        initStatus,
        openConfirmProgressDialog,
        closeConfirmProgressDialog,
    };
};
