import { AnalysisProgressFetchListService } from '@/models/service/EmotionAnalysis/AnalysisProgressFetchListService';
import { container } from 'tsyringe';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { ErrorStatus } from '@/types/common';
import { AnalysisProgressInfo } from '@/models/interface/EmotionAnalysis/IAnalysisProgressRepository';
import { isFailedResponse } from '@/models/interface/common';
import { useAnalysisProgressListProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisProgressListProgressCircular';

const analysisProgressFetchIndex = container.resolve(AnalysisProgressFetchListService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisProgressList = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const analysisProgressList = ref<AnalysisProgressInfo[]>([]);

    provide('error', error);
    provide('analysisProgressList', analysisProgressList);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisProgressList = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const analysisProgressList = inject('analysisProgressList') as Ref<AnalysisProgressInfo[]>;

    const initStatus = (): void => {
        error.value.hasError = false;
        error.value.message = '';
    };

    const { analysisProgressListProgressStart, analysisProgressListProgressEnd } =
        useAnalysisProgressListProgressCircular();
    const fetchList = async (): Promise<void> => {
        analysisProgressListProgressStart();
        const tenantIdentifier = 'platform1';

        const response = await analysisProgressFetchIndex.handle(tenantIdentifier);

        if (isFailedResponse(response)) {
            error.value.hasError = true;
            error.value.message = response.data.data.message;
            analysisProgressListProgressEnd();
            return;
        }

        initStatus();
        analysisProgressList.value = response.data.data.analysisProgressList;
        analysisProgressListProgressEnd();
    };

    return {
        error,
        analysisProgressList,
        fetchList,
    };
};
