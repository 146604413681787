import { useTime } from '@/composables/function/Common/useTime';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';
import { BasicEmotionInfo, YAxisType, yAxisTypeValues } from '@/types/emotion-analysis';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { ChartData } from 'chart.js';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideScatterChartData = () => {
    const localStorageYAxis = localStorage.getItem('yAxis') as YAxisType;
    const yAxisType = ref<YAxisType>(localStorageYAxis ?? '固定');
    const axisRange = ref<number[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const localStorageOpenPanels = JSON.parse(localStorage.getItem('openResultPanels')!);
    const openResultPanels = ref<number[]>(localStorageOpenPanels ?? []);

    provide('yAxisType', yAxisType);
    provide('axisRange', axisRange);
    provide('openResultPanels', openResultPanels);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useScatterChartData = () => {
    const yAxisType = inject('yAxisType') as Ref<YAxisType>;
    const axisRange = inject('axisRange') as Ref<number[]>;
    const openResultPanels = inject('openResultPanels') as Ref<number[]>;

    const setYAxisTypeToLocalStorage = (): void => {
        localStorage.setItem('yAxis', yAxisType.value);
    };

    const setOpenResultPanelsLocalStorage = (openPanels: number[]): void => {
        localStorage.setItem('openResultPanels', JSON.stringify(openPanels));
        openResultPanels.value = openPanels;
    };

    const initAxisRange = (): void => {
        axisRange.value = [0, maxDurationOfCall()];
    };

    const opColors = ['#1e90ff', '#ff7f50', '#3cb371'];
    const csColors = ['#87cefa', '#ffa07a', '#9acd32'];

    const { selectedEmotionAnalysis } = useAnalysisListSelect();
    const createChartData = (target: BasicEmotionInfo): ChartData<'scatter'> => {
        const chartData = [] as ChartData<'scatter'>['datasets'];
        selectedEmotionAnalysis.value.forEach((emotionAnalysis, index) => {
            const opAnalysis = emotionAnalysis.opResult.map(opAnalysis => {
                return {
                    x: opAnalysis.end,
                    y: opAnalysis[target.key],
                };
            });

            chartData.push({
                data: opAnalysis,
                showLine: true,
                label: `OP: ${emotionAnalysis.opName} [No.${emotionAnalysis.recordFileId}]`,
                borderColor: opColors[index],
                tension: 0.1,
                pointStyle: 'triangle',
            });

            const csAnalysis = emotionAnalysis.csResult.map(csAnalysis => {
                return {
                    x: csAnalysis.end,
                    y: csAnalysis[target.key],
                };
            });

            chartData.push({
                data: csAnalysis,
                showLine: true,
                label: `CS: No.${emotionAnalysis.recordFileId}`,
                borderColor: csColors[index],
                tension: 0.1,
                pointStyle: 'rectRot',
            });
        });

        return {
            datasets: chartData,
        };
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
    const createChartOptions = (target: BasicEmotionInfo) => {
        const { convertToMinutesFormat } = useTime();
        return {
            scales: {
                y: buildYAxisOption(target.max),
                x: {
                    min: axisRange.value[0],
                    max: axisRange.value[1],
                    ticks: {
                        stepSize: 30,
                        callback: function (value: number) {
                            return convertToMinutesFormat(value);
                        },
                    },
                },
            },
            plugins: {
                tooltip: {
                    titleAlign: 'center',
                    bodyAlign: 'center',
                    bodySpacing: 10,
                    footerAlign: 'center',
                    callbacks: {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        title: (tooltipItem: any) => {
                            return tooltipItem[0].dataset.label;
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        label: (tooltipItem: any) => {
                            return tooltipItem.formattedValue;
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        footer: (tooltipItem: any) => {
                            return 'Time:' + convertToMinutesFormat(tooltipItem[0].parsed.x);
                        },
                    },
                },
            },
        };
    };

    const { audioCurrentTimeRate } = useTargetAudioData();
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const chartAudioPlugin = {
        id: 'audio-seekbar-plugin',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeDraw: (chart: any) => {
            const chartScales = chart.config.options.scales;
            const chartArea = chart.chartArea;
            chart.ctx.fillStyle = '#B0BEC5';
            chart.ctx.fillRect(
                chartArea.left,
                chartArea.top,
                chartArea.width * audioCurrentTimeRate(chartScales.x),
                chartArea.height,
            );
        },
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const buildYAxisOption = (max: BasicEmotionInfo['max']) => {
        if (yAxisType.value === yAxisTypeValues[0]) {
            return {
                max: max,
                ticks: {
                    stepSize: 10,
                },
            };
        }

        return {
            ticks: {
                stepSize: 10,
            },
        };
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const maxDurationOfCall = () => {
        return Math.max.apply(
            null,
            selectedEmotionAnalysis.value.map(analysis => Number(analysis.durationOfCall)),
        );
    };

    return {
        yAxisType,
        axisRange,
        initAxisRange,
        setYAxisTypeToLocalStorage,
        setOpenResultPanelsLocalStorage,
        createChartData,
        createChartOptions,
        maxDurationOfCall,
        chartAudioPlugin,
        openResultPanels,
    };
};
