import { useRecordFile } from '@/composables/function/EmotionAnalysis/useRecordFile';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useFormValidation = () => {
    const { checkFileExtensions } = useRecordFile();

    const analysisRequestRules = {
        file: [
            (v: File) => !!v || '対象の録音ファイルを添付してください。',
            (v: File) => !v || checkFileExtensions(v) || 'ファイル形式を確認してください。',
        ],
        callType: [(v: string) => !!v || '通話種別を選択してください。'],
        opName: [
            (v: string) => !!v || 'オペレーター名を入力してください。',
            (v: string) => v.length <= 100 || 'オペレーター名は100文字以内で入力してください。',
        ],
        callStatus: [
            (v: string) => !!v || '通話ステータスを入力してください。',
            (v: string) => v.length <= 100 || '通話ステータスは100文字以内で入力してください。',
        ],
    };

    const searchRules = (opName: string, callStatus: string): boolean => {
        return opName === '' && callStatus === '';
    };

    return {
        analysisRequestRules,
        searchRules,
    };
};
