






import { defineComponent } from '@vue/composition-api';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';

export default defineComponent({
    setup() {
        const { stop } = useTargetAudioData();

        return {
            stop,
        };
    },
});
