import { useAnalysisList } from '@/composables/store/EmotionAnalysis/useAnalysisList';
import { EmotionAnalysisInfo } from '@/models/interface/EmotionAnalysis/IEmotionAnalysisRepository';
import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisListSelect = () => {
    const selectedEmotionAnalysis = ref<EmotionAnalysisInfo[]>([]);
    const selectedListIndexes = ref<number[]>([]);
    const remainSelectedListIndexes = ref<number[]>([]);

    provide('selectedEmotionAnalysis', selectedEmotionAnalysis);
    provide('selectedListIndexes', selectedListIndexes);
    provide('remainSelectedListIndexes', remainSelectedListIndexes);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisListSelect = () => {
    const selectedEmotionAnalysis = inject('selectedEmotionAnalysis') as Ref<EmotionAnalysisInfo[]>;
    const selectedListIndexes = inject('selectedListIndexes') as Ref<number[]>;
    const remainSelectedListIndexes = inject('remainSelectedListIndexes') as Ref<number[]>;

    const setEmotionAnalysis = (data: EmotionAnalysisInfo[]): void => {
        selectedEmotionAnalysis.value = data;
    };

    const resetSelectedAnalysis = (): void => {
        selectedEmotionAnalysis.value = [];
        selectedListIndexes.value = [];
        remainSelectedListIndexes.value = [];
    };

    const { emotionAnalysisList } = useAnalysisList();
    const overWriteEmotionAnalysis = (): void => {
        selectedListIndexes.value = Array.from(new Set(selectedListIndexes.value)).splice(0, 3);

        const target = selectedListIndexes.value.map(index => {
            return emotionAnalysisList.value[index];
        });

        setEmotionAnalysis(target);
    };

    const writeSelectedFromRemainSelected = (index: number): void => {
        remainSelectedListIndexes.value.push(index);
        remainSelectedListIndexes.value.forEach(index => selectedListIndexes.value.unshift(index));
    };

    const changeSelected = (): void => {
        //changeイベントの為星三つ固定でも追加される
        if (remainSelectedListIndexes.value.length >= 3 && selectedListIndexes.value.length >= 3) {
            selectedListIndexes.value = selectedListIndexes.value.splice(0, 3);
            return;
        }
        //ex) 星一つ状態 + 普通1から解除の時
        if (remainSelectedListIndexes.value.length === selectedListIndexes.value.length) {
            selectedListIndexes.value = Array.from(remainSelectedListIndexes.value);
            overWriteEmotionAnalysis();
            return;
        }

        //ex)星で1つ以上固定しながら選択する時
        if (selectedListIndexes.value.length > 3) {
            if (remainSelectedListIndexes.value.length) remainIndexExcludeFromSelectedIndex();
            selectedListIndexes.value.shift();
        }

        remainSelectedListIndexes.value.forEach(index => selectedListIndexes.value.unshift(index));
        overWriteEmotionAnalysis();
    };

    const remainIndexExcludeFromSelectedIndex = (): void => {
        selectedListIndexes.value = selectedListIndexes.value.filter(selectedIndex => !isRemainSelected(selectedIndex));
    };

    const remainSelect = (index: number): void => {
        if (remainSelectedListIndexes.value.length === 3) return;

        writeSelectedFromRemainSelected(index);
        overWriteEmotionAnalysis();
    };

    const forgetSelect = (index: number): void => {
        remainSelectedListIndexes.value = remainSelectedListIndexes.value.filter(existIndex => existIndex !== index);
        selectedListIndexes.value = selectedListIndexes.value.filter(existIndex => existIndex !== index);
        overWriteEmotionAnalysis();
    };

    const isSelected = (): boolean => {
        return selectedEmotionAnalysis.value.length > 0;
    };

    const isRemainSelected = (index: number): boolean => {
        return remainSelectedListIndexes.value.includes(index);
    };

    return {
        selectedEmotionAnalysis,
        selectedListIndexes,
        remainSelectedListIndexes,
        setEmotionAnalysis,
        resetSelectedAnalysis,
        overWriteEmotionAnalysis,
        isSelected,
        isRemainSelected,
        changeSelected,
        remainSelect,
        forgetSelect,
    };
};
