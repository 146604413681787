

















import { defineComponent } from '@vue/composition-api';
import ResultListComponent from '@/components/EmotionAnalysis/ecosystems/ResultList.vue';
import ChartAnalysisComponent from '@/components/EmotionAnalysis/ecosystems/ChartAnalysis.vue';
import AnalysisRequestDialogComponent from '@/components/EmotionAnalysis/organisms/AnalysisRequestDialog.vue';
import SearchDialogComponent from '@/components/EmotionAnalysis/organisms/SearchDialog.vue';
import ConfirmProgressDialog from '@/components/EmotionAnalysis/organisms/ConfirmProgressDialog.vue';

import {
    provideAnalysisRequestDialog,
    useAnalysisRequestDialog,
} from '@/composables/store/EmotionAnalysis/useAnalysisRequestDialog';
import { provideAnalysisList } from '@/composables/store/EmotionAnalysis/useAnalysisList';
import { provideSearchDialog, useSearchDialog } from '@/composables/store/EmotionAnalysis/useSearchDialog';
import { providePagination } from '@/composables/store/Common/usePagination';
import { provideScatterChartData } from '@/composables/store/EmotionAnalysis/useScatterChartData';
import { provideTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { provideAnalysisListProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisLIstProgressCircular';
import { provideAnalysisRequestProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisRequestProgressCircular';
import { provideAnalysisProgressListProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisProgressListProgressCircular';
import { provideAnalysisProgressList } from '@/composables/store/EmotionAnalysis/useAnalysisProgressList';
import {
    provideConfirmProgressDialog,
    useConfirmProgressDialog,
} from '@/composables/store/EmotionAnalysis/useConfirmProgressDialog';
import { provideAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';

export default defineComponent({
    components: {
        ConfirmProgressDialog,
        ResultList: ResultListComponent,
        ChartAnalysis: ChartAnalysisComponent,
        AnalysisRequestDialog: AnalysisRequestDialogComponent,
        SearchDialog: SearchDialogComponent,
    },
    setup() {
        // 依存性なし
        provideAnalysisListProgressCircular();
        provideAnalysisRequestProgressCircular();
        provideAnalysisProgressListProgressCircular();
        providePagination();
        provideScatterChartData();
        provideTargetAudioData();
        provideAnalysisProgressList();

        // 依存性あり
        provideAnalysisList();
        provideAnalysisRequestDialog();
        provideSearchDialog();
        provideConfirmProgressDialog();
        provideAnalysisListSelect();

        const { closeAnalysisRequestDialog } = useAnalysisRequestDialog();
        const { closeSearchDialog } = useSearchDialog();
        const { closeConfirmProgressDialog } = useConfirmProgressDialog();
        addEventListener('popstate', () => {
            closeAnalysisRequestDialog();
            closeSearchDialog();
            closeConfirmProgressDialog();
        });
    },
});
