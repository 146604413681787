




























































import { defineComponent, ref } from '@vue/composition-api';
import { useSearchDialog } from '@/composables/store/EmotionAnalysis/useSearchDialog';
import { useFormValidation } from '@/composables/function/EmotionAnalysis/useFormValidation';
import { useAnalysisList } from '@/composables/store/EmotionAnalysis/useAnalysisList';
import { usePagination } from '@/composables/store/Common/usePagination';

export default defineComponent({
    setup() {
        const {
            error,
            searchDialogFlag,
            closeSearchDialog,
            initSearchDialogForm,
            searchDialogForm,
            targetTenant,
            callStatusList,
        } = useSearchDialog();
        const { fetchList } = useAnalysisList();
        const valid = ref(false);
        const { searchRules } = useFormValidation();
        const { setFirstPageNumber } = usePagination();

        const submit = async (): Promise<void> => {
            await fetchList(targetTenant.value);
            if (!error.value.hasError) {
                closeSearchDialog();
                setFirstPageNumber();
            }
        };

        const resetSearch = (): void => {
            closeSearchDialog();
            initSearchDialogForm();
            fetchList(targetTenant.value);
        };

        const getTargetTenantName = (): string => {
            if (targetTenant.value === undefined) return '';
            return targetTenant.value.name;
        };

        return {
            error,
            searchDialogFlag,
            searchDialogForm,
            targetTenant,
            valid,
            closeSearchDialog,
            searchRules,
            submit,
            getTargetTenantName,
            resetSearch,
            callStatusList,
        };
    },
});
