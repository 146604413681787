











import { defineComponent } from '@vue/composition-api';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';

export default defineComponent({
    setup() {
        const { targetAudioData, audioDataCurrentTime } = useTargetAudioData();

        return {
            targetAudioData,
            audioDataCurrentTime,
        };
    },
});
