import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisRequestProgressCircular = () => {
    const isAnalysisRequestProgressing = ref(false);

    provide('isAnalysisRequestProgressing', isAnalysisRequestProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisRequestProgressCircular = () => {
    const isAnalysisRequestProgressing = inject('isAnalysisRequestProgressing') as Ref<boolean>;

    const analysisRequestProgressStart = (): void => {
        isAnalysisRequestProgressing.value = true;
    };

    const analysisRequestProgressEnd = (): void => {
        isAnalysisRequestProgressing.value = false;
    };

    return {
        isAnalysisRequestProgressing,
        analysisRequestProgressStart,
        analysisRequestProgressEnd,
    };
};
