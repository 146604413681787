import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisListProgressCircular = () => {
    const isAnalysisListProgressing = ref(false);

    provide('isAnalysisListProgressing', isAnalysisListProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisListProgressCircular = () => {
    const isAnalysisListProgressing = inject('isAnalysisListProgressing') as Ref<boolean>;

    const analysisListProgressStart = (): void => {
        isAnalysisListProgressing.value = true;
    };

    const analysisListProgressEnd = (): void => {
        isAnalysisListProgressing.value = false;
    };

    return {
        isAnalysisListProgressing,
        analysisListProgressStart,
        analysisListProgressEnd,
    };
};
