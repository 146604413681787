


















import { defineComponent } from '@vue/composition-api';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';

export default defineComponent({
    setup() {
        //NOTE: v-list-item-groupは初期値index指定(2 = 1.0sec)
        const selectedSpeed = 2;
        const speedOptions = [0.25, 0.5, 1.0, 1.25, 1.5, 2.0];
        const { changeSpeed } = useTargetAudioData();

        return {
            selectedSpeed,
            speedOptions,
            changeSpeed,
        };
    },
});
