// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useRecordFile = () => {
    const allowExtensions = ['.wav', '.mp3'];

    const checkFileExtensions = (file: File): boolean => {
        const extension = getExtension(file.name);
        return allowExtensions.indexOf(extension) !== -1;
    };

    const getExtension = (fileName: string): string => {
        const position = fileName.lastIndexOf('.');
        if (position === -1) return '';

        return fileName.slice(position);
    };

    return {
        checkFileExtensions,
    };
};
