







import { defineComponent } from '@vue/composition-api';
import { useAnalysisRequestDialog } from '@/composables/store/EmotionAnalysis/useAnalysisRequestDialog';

export default defineComponent({
    setup() {
        const { openAnalysisRequestDialog } = useAnalysisRequestDialog();

        return {
            openAnalysisRequestDialog,
        };
    },
});
