

























































import { defineComponent, watch, watchEffect } from '@vue/composition-api';
import { useAnalysisList } from '@/composables/store/EmotionAnalysis/useAnalysisList';
import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';
import { usePagination } from '@/composables/store/Common/usePagination';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { useSearchDialog } from '@/composables/store/EmotionAnalysis/useSearchDialog';
import { useTime } from '@/composables/function/Common/useTime';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useAnalysisListProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisLIstProgressCircular';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { isAnalysisListProgressing } = useAnalysisListProgressCircular();
        const { pageNumber } = usePagination();
        const { fetchList, emotionAnalysisList } = useAnalysisList();
        const { targetTenant } = useSearchDialog();
        const {
            selectedListIndexes,
            changeSelected,
            remainSelect,
            forgetSelect,
            isRemainSelected,
            resetSelectedAnalysis,
        } = useAnalysisListSelect();
        const { setTargetAudioIndex, setTargetAudioData } = useTargetAudioData();
        const { convertToMinutesFormatNumber } = useTime();

        /** 初期データを用意 */
        const setData = async (): Promise<void> => {
            await fetchList(targetTenant.value);
        };

        setData();

        // ページ番号が変更された時、選択を初期化する
        watch(pageNumber, () => {
            resetSelectedAnalysis();
        });

        watchEffect(() => {
            // オーディオタグのデータを初期化
            if (selectedListIndexes.value.length === 0) {
                setTargetAudioIndex(undefined);
                setTargetAudioData(undefined);
            }
        });

        return {
            isAnalysisListProgressing,
            selectedListIndexes,
            emotionAnalysisList,
            convertToMinutesFormatNumber,
            changeSelected,
            remainSelect,
            forgetSelect,
            isRemainSelected,
        };
    },
});
