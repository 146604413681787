



















import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { defineComponent, watch } from '@vue/composition-api';
export default defineComponent({
    setup() {
        const { selectedEmotionAnalysis } = useAnalysisListSelect();
        const { targetAudioIndex, targetAudioData, setTargetAudioIndex, setTargetAudioData } = useTargetAudioData();

        watch(targetAudioIndex, () => {
            if (targetAudioIndex.value === undefined) return;
            setTargetAudioData(selectedEmotionAnalysis.value[targetAudioIndex.value]);
        });

        watch(selectedEmotionAnalysis, () => {
            if (targetAudioData.value === undefined) return;
            const match = selectedEmotionAnalysis.value.find(
                emotionAnalysis => emotionAnalysis.recordFileId === targetAudioData.value?.recordFileId,
            );

            if (!match) {
                setTargetAudioIndex(undefined);
                setTargetAudioData(undefined);
            }
        });

        return {
            selectedEmotionAnalysis,
            targetAudioIndex,
        };
    },
});
