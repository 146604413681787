



























































import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useConfirmProgressDialog } from '@/composables/store/EmotionAnalysis/useConfirmProgressDialog';
import { useAnalysisProgressList } from '@/composables/store/EmotionAnalysis/useAnalysisProgressList';
import { useTime } from '@/composables/function/Common/useTime';
import { useAnalysisProgressListProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisProgressListProgressCircular';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { error, confirmProgressDialogFlag, closeConfirmProgressDialog } = useConfirmProgressDialog();

        const { analysisProgressList } = useAnalysisProgressList();

        const { convertToMinutesFormat } = useTime();
        const { isAnalysisProgressListProgressing } = useAnalysisProgressListProgressCircular();

        const isNotLoadingAndExistData = (): boolean => {
            return !!analysisProgressList.value.length && !isAnalysisProgressListProgressing.value;
        };

        return {
            error,
            isAnalysisProgressListProgressing,
            confirmProgressDialogFlag,
            closeConfirmProgressDialog,
            analysisProgressList,
            convertToMinutesFormat,
            isNotLoadingAndExistData,
        };
    },
});
