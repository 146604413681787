













import { defineComponent, onMounted, watch } from '@vue/composition-api';
import { useSearchDialog } from '@/composables/store/EmotionAnalysis/useSearchDialog';
import { useAnalysisList } from '@/composables/store/EmotionAnalysis/useAnalysisList';
import { useAnalysisListSelect } from '@/composables/store/EmotionAnalysis/useAnalysisListSelect';
import { usePagination } from '@/composables/store/Common/usePagination';

export default defineComponent({
    setup() {
        const { fetchList } = useAnalysisList();
        const { fetchSchemaList, schemaList, targetTenant, setTargetTenant, initSearchDialogForm } = useSearchDialog();
        const { setFirstPageNumber } = usePagination();
        const { resetSelectedAnalysis } = useAnalysisListSelect();

        onMounted(async (): Promise<void> => {
            await fetchSchemaList();
        });

        /** 対象テナント（スキーマ情報）変更を監視 */
        watch(targetTenant, () => {
            initSearchDialogForm();
            setFirstPageNumber();
            fetchList(targetTenant.value);
            setTargetTenant(targetTenant.value);
            resetSelectedAnalysis();
        });

        return {
            schemaList,
            targetTenant,
        };
    },
});
