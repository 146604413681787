import {
    AnalysisFetchResponse,
    IEmotionAnalysisRepository,
} from '@/models/interface/EmotionAnalysis/IEmotionAnalysisRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class AnalysisFetchListService {
    constructor(@inject('IEmotionAnalysisRepository') private readonly repository: IEmotionAnalysisRepository) {}

    async handle(
        tenantIdentifier: string,
        pageSize: number,
        pageNumber: number,
        opName: string | undefined,
        statusList: string[],
    ): Promise<AnalysisFetchResponse> {
        return this.repository.getAnalysisIndex(tenantIdentifier, pageSize, pageNumber, opName, statusList);
    }
}
