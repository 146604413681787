


























































import { defineComponent, ref } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { CallTypeValues } from '@/types/emotion-analysis';
import { useAnalysisRequestDialog } from '@/composables/store/EmotionAnalysis/useAnalysisRequestDialog';
import { useAnalysisRequestProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisRequestProgressCircular';
import { useFormValidation } from '@/composables/function/EmotionAnalysis/useFormValidation';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { error, analysisRequestDialogFlag, closeAnalysisRequestDialog, form, postAnalysis } =
            useAnalysisRequestDialog();

        const { isAnalysisRequestProgressing } = useAnalysisRequestProgressCircular();
        const valid = ref(false);
        const { analysisRequestRules } = useFormValidation();

        return {
            error,
            analysisRequestDialogFlag,
            closeAnalysisRequestDialog,
            form,
            postAnalysis,
            CallTypeValues,
            valid,
            analysisRequestRules,
            isAnalysisRequestProgressing,
        };
    },
});
