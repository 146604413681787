export type AnalysisRequestForm = {
    file: File | undefined;
    callType: CallType;
    opName: string;
    callStatus: string;
};

export const CallTypeValues = ['架電', '受電'] as const;
export type CallTypeType = typeof CallTypeValues;
export type CallType = CallTypeType[number];

export const yAxisTypeValues = ['固定', '変動'] as const;
export type YAxisTypeType = typeof yAxisTypeValues;
export type YAxisType = YAxisTypeType[number];

export type SearchDialogForm = {
    opName: string;
    statusList: string[];
};

/**
 * 基本感情パラメーター
 * [title] 感情名
 * [key] キー
 * [type] ポジ・ネガのタイプ
 * [explain] パラメーターの説明
 * [max] 最大値
 * [min] 最小値
 */
export const basicEmotionValues = [
    {
        title: 'エネルギー',
        key: 'energy',
        type: 'pos',
        explain:
            '高領域では、感情の昂ぶり・非常に精力的、低領域では、興味の欠如（退屈）・寄り添いの不足・睡眠不足等の体調不良を示唆',
        max: 50,
        min: 0,
    },
    {
        title: '喜び',
        key: 'content',
        type: 'pos',
        explain: '満足、喜びを示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '情熱',
        key: 'saf',
        type: 'pos',
        explain: '非常に高い興味を示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '声のエネルギー',
        key: 'voiceEnergy',
        type: 'pos',
        explain: '声に含まれるエネルギーを測定し、高いほどエネルギッシュな発話を示唆',
        max: 100,
        min: 0,
    },
    {
        title: '悲しみ',
        key: 'sad',
        type: 'neg',
        explain: '不満あるいは悲しみを示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '怒り・憤り',
        key: 'angry',
        type: 'neg',
        explain: '話者の攻撃性を示唆する指標',
        max: 30,
        min: 0,
    },
    {
        title: 'ストレス',
        key: 'stress',
        type: 'neg',
        explain: 'ストレスや精神的な負荷を示す感情パラメータで根幹となる指標',
        max: 30,
        min: 0,
    },
    {
        title: '不確実性',
        key: 'uncertainty',
        type: 'neg',
        explain: '自信の無さを示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '不安',
        key: 'uneasy',
        type: 'neg',
        explain: '心が落ち着かない状態を示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '躊躇',
        key: 'hesitation',
        type: 'neg',
        explain: '発言へのためらいの度合いを示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '不満',
        key: 'dissatisfaction',
        type: 'neg',
        explain: '発話者の不満・ネガティブな反応を表現する',
        max: 30,
        min: 0,
    },
    {
        title: '感情起伏',
        key: 'emotional',
        type: 'neither',
        explain: '感情起伏の度合いを表現',
        max: 30,
        min: 0,
    },
    {
        title: '集中',
        key: 'concentration',
        type: 'neither',
        explain: '話者の集中の度合い示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '期待',
        key: 'anticipation',
        type: 'neither',
        explain: '高領域ではポジティブな期待、ネガティブな期待双方を示唆',
        max: 30,
        min: 0,
    },
    {
        title: '思考',
        key: 'think',
        type: 'neither',
        explain: '思考しながら発言している状態を示す指標',
        max: 30,
        min: 0,
    },
    {
        title: '想像力',
        key: 'imagine',
        type: 'neither',
        explain: '高領域では何らかの説明を行う際、ものごとの伝え方に対して想像力が働いていることを示す。',
        max: 30,
        min: 0,
    },
    {
        title: '感情/論理',
        key: 'emoCogRatio',
        type: 'neither',
        explain: '感情的（情動的）な思考に紐づいた内容なのか論理的(冷静な)思考に紐づいた内容なのかのバランスを示す',
        max: 500,
        min: 1,
    },
    {
        title: '極端な起伏',
        key: 'extremeEmotion',
        type: 'neither',
        explain: '感情全般の動きの極限さを示す指標',
        max: 30,
        min: 0,
    },
] as const;
export type BasicEmotionType = typeof basicEmotionValues;
export type BasicEmotionInfo = BasicEmotionType[number];

export type BasicEmotionItemType = 'pos' | 'neg' | 'neither';
