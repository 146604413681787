import { isFailedResponse } from '@/models/interface/common';
import { AnalysisImplementService } from '@/models/service/EmotionAnalysis/AnalysisImplementService';
import { ErrorStatus } from '@/types/common';
import { AnalysisRequestForm, CallTypeValues } from '@/types/emotion-analysis';
import { provide, inject, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { useAnalysisRequestProgressCircular } from '@/composables/store/EmotionAnalysis/useAnalysisRequestProgressCircular';

const analysisImplement = container.resolve(AnalysisImplementService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisRequestDialog = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const analysisRequestDialogFlag = ref(false);

    const form = ref<AnalysisRequestForm>({
        file: undefined,
        callType: CallTypeValues[0],
        opName: '',
        callStatus: '',
    });

    provide('error', error);
    provide('analysisRequestDialogFlag', analysisRequestDialogFlag);
    provide('form', form);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisRequestDialog = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const analysisRequestDialogFlag = inject('analysisRequestDialogFlag') as Ref<boolean>;
    const form = inject('form') as Ref<AnalysisRequestForm>;

    const openAnalysisRequestDialog = (): void => {
        initDialog();
        analysisRequestDialogFlag.value = true;
    };

    const closeAnalysisRequestDialog = (): void => {
        analysisRequestDialogFlag.value = false;
    };

    const initDialog = (): void => {
        form.value.file = undefined;
        form.value.callType = CallTypeValues[0];
        form.value.opName = '';
        form.value.callStatus = '';
        error.value.hasError = false;
        error.value.message = '';
    };

    const { analysisRequestProgressStart, analysisRequestProgressEnd } = useAnalysisRequestProgressCircular();

    /**
     * 録音ファイルの解析リクエスト
     * @returns
     */
    const postAnalysis = async (): Promise<void> => {
        analysisRequestProgressStart();

        if (form.value.file === undefined) return;
        const result = await analysisImplement.handle(
            form.value.file,
            form.value.callType,
            form.value.opName,
            form.value.callStatus,
        );

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            analysisRequestProgressEnd();
            return;
        }

        analysisRequestProgressEnd();
        closeAnalysisRequestDialog();
    };

    return {
        error,
        analysisRequestDialogFlag,
        form,
        openAnalysisRequestDialog,
        closeAnalysisRequestDialog,
        postAnalysis,
    };
};
