import { EmotionAnalysisInfo } from '@/models/interface/EmotionAnalysis/IEmotionAnalysisRepository';
import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideTargetAudioData = () => {
    const targetAudioIndex = ref<undefined | number>(undefined);
    const targetAudioData = ref<undefined | EmotionAnalysisInfo>(undefined);
    const audioDataCurrentTime = ref<number>(0);
    const audioElement = ref<HTMLAudioElement>();
    const playFlag = ref<boolean>(false);

    provide('targetAudioIndex', targetAudioIndex);
    provide('targetAudioData', targetAudioData);
    provide('audioDataCurrentTime', audioDataCurrentTime);
    provide('audioElement', audioElement);
    provide('playFlag', playFlag);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useTargetAudioData = () => {
    const targetAudioIndex = inject('targetAudioIndex') as Ref<undefined | number>;
    const targetAudioData = inject('targetAudioData') as Ref<undefined | EmotionAnalysisInfo>;
    const audioDataCurrentTime = inject('audioDataCurrentTime') as Ref<number>;
    const audioElement = inject('audioElement') as Ref<HTMLAudioElement>;
    const playFlag = inject('playFlag') as Ref<boolean>;

    const setTargetAudioIndex = (target: number | undefined): void => {
        targetAudioIndex.value = target;
    };

    const setTargetAudioData = (target: EmotionAnalysisInfo | undefined): void => {
        targetAudioData.value = target;
        playFlag.value = false;
    };

    const changeAudioCurrentTime = (target: HTMLAudioElement): void => {
        if (canRenderingTime(audioDataCurrentTime.value, target.currentTime)) {
            audioDataCurrentTime.value = target.currentTime;
        }
    };

    const canRenderingTime = (oldTime: number, newTime: number): boolean => {
        return Math.abs(oldTime - newTime) > 1;
    };

    //NOTE: 現在の再生時間の比率
    const audioCurrentTimeRate = (chartScalesX: { min: number; max: number }): number => {
        if (!targetAudioData || !targetAudioData.value) return 0;
        if (chartScalesX.min > audioDataCurrentTime.value) return 0;
        const rate = (audioDataCurrentTime.value - chartScalesX.min) / (chartScalesX.max - chartScalesX.min);
        return rate < 1 ? rate : 1;
    };

    const stop = (): void => {
        if (!audioElement || !audioElement.value) return;
        audioElement.value.pause();
    };

    const play = (): void => {
        if (!audioElement || !audioElement.value) return;
        audioElement.value.play();
    };

    const changeSpeed = (speed: number): void => {
        if (!audioElement || !audioElement.value) return;
        audioElement.value.playbackRate = speed;
    };

    return {
        targetAudioIndex,
        targetAudioData,
        audioDataCurrentTime,
        playFlag,
        audioElement,
        setTargetAudioIndex,
        setTargetAudioData,
        changeAudioCurrentTime,
        audioCurrentTimeRate,
        play,
        stop,
        changeSpeed,
    };
};
