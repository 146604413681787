import {
    AnalysisRequestResponse,
    IEmotionAnalysisRepository,
} from '@/models/interface/EmotionAnalysis/IEmotionAnalysisRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class AnalysisImplementService {
    constructor(@inject('IEmotionAnalysisRepository') private readonly repository: IEmotionAnalysisRepository) {}

    async handle(file: File, callType: string, opName: string, callStatus: string): Promise<AnalysisRequestResponse> {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('callType', callType);
        formData.append('opName', opName);
        formData.append('callStatus', callStatus);

        return this.repository.postAnalysisImplement(formData);
    }
}
