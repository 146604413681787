






import { defineComponent } from '@vue/composition-api';
import { usePagination } from '@/composables/store/Common/usePagination';
import { useAnalysisList } from '@/composables/store/EmotionAnalysis/useAnalysisList';
import { useSearchDialog } from '@/composables/store/EmotionAnalysis/useSearchDialog';

export default defineComponent({
    setup() {
        const { totalPage, pageNumber, setPageNumber } = usePagination();
        const { targetTenant } = useSearchDialog();
        const { fetchList } = useAnalysisList();

        const changePageNumber = (target: number): void => {
            setPageNumber(target);
            fetchList(targetTenant.value);
        };

        return {
            totalPage,
            pageNumber,
            changePageNumber,
        };
    },
});
