






























import { defineComponent } from '@vue/composition-api';
import SearchButtonComponent from '@/components/EmotionAnalysis/molecules/SearchButton.vue';
import ProgressConfirmButtonComponent from '@/components/EmotionAnalysis/molecules/ProgressConfirmButton.vue';
import AnalysisRequestButtonComponent from '@/components/EmotionAnalysis/molecules/AnalysisRequestButton.vue';
import TargetTenantSelectComponent from '@/components/EmotionAnalysis/molecules/TargetTenantSelect.vue';
import PaginationComponent from '@/components/Common/molecules/Pagination.vue';
import AnalysisResultListComponent from '@/components/EmotionAnalysis/organisms/AnalysisResultList.vue';

export default defineComponent({
    components: {
        SearchButton: SearchButtonComponent,
        ProgressConfirmButton: ProgressConfirmButtonComponent,
        AnalysisRequestButton: AnalysisRequestButtonComponent,
        TargetTenantSelect: TargetTenantSelectComponent,
        Pagination: PaginationComponent,
        AnalysisResultList: AnalysisResultListComponent,
    },
});
