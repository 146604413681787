import { CallStatusFetchResponse, ITenantARepository } from '@/models/interface/EmotionAnalysis/ITenantARepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class FetchCallStatusListService {
    constructor(@inject('ITenantARepository') private readonly repository: ITenantARepository) {}

    async handle(tenantIdentifier: string): Promise<CallStatusFetchResponse> {
        return this.repository.getCallStatusIndex(tenantIdentifier);
    }
}
