






import { defineComponent } from '@vue/composition-api';
import { useConfirmProgressDialog } from '@/composables/store/EmotionAnalysis/useConfirmProgressDialog';

export default defineComponent({
    setup() {
        const { openConfirmProgressDialog } = useConfirmProgressDialog();

        return {
            openConfirmProgressDialog,
        };
    },
});
