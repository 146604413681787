
























import { defineComponent } from '@vue/composition-api';
import AudioControlRangeComponent from '@/components/EmotionAnalysis/molecules/AudioControlRange.vue';
import AudioPlayButtonComponent from '@/components/EmotionAnalysis/molecules/AudioPlayButton.vue';
import AudioStopButtonComponent from '@/components/EmotionAnalysis/molecules/AudioStopButton.vue';
import AudioSpeedMenuComponent from '@/components/EmotionAnalysis/molecules/AudioSpeedMenu.vue';
import { useTargetAudioData } from '@/composables/store/EmotionAnalysis/useTargetAudioData';
import { useTime } from '@/composables/function/Common/useTime';

export default defineComponent({
    components: {
        AudioControlRange: AudioControlRangeComponent,
        AudioPlayButton: AudioPlayButtonComponent,
        AudioStopButton: AudioStopButtonComponent,
        AudioSpeedMenu: AudioSpeedMenuComponent,
    },
    setup() {
        const { cutOffDecimalPointTime } = useTime();
        const { targetAudioData, audioDataCurrentTime, playFlag } = useTargetAudioData();

        return {
            cutOffDecimalPointTime,
            audioDataCurrentTime,
            playFlag,
            targetAudioData,
        };
    },
});
