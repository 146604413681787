import { inject, injectable } from 'tsyringe';
import {
    AnalysisProgressFetchResponse,
    IAnalysisProgressRepository,
} from '@/models/interface/EmotionAnalysis/IAnalysisProgressRepository';

@injectable()
export class AnalysisProgressFetchListService {
    constructor(@inject('IAnalysisProgressRepository') private readonly repository: IAnalysisProgressRepository) {}

    async handle(tenantIdentifier: string): Promise<AnalysisProgressFetchResponse> {
        return this.repository.getAnalysisProgressIndex(tenantIdentifier);
    }
}
