import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const providePagination = () => {
    const totalPage = ref(1);
    const pageSize = ref(20);
    const pageNumber = ref(1);

    provide('totalPage', totalPage);
    provide('pageSize', pageSize);
    provide('pageNumber', pageNumber);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const usePagination = () => {
    const totalPage = inject('totalPage') as Ref<number>;
    const pageSize = inject('pageSize') as Ref<number>;
    const pageNumber = inject('pageNumber') as Ref<number>;

    const setTotalPage = (totalCount: number): void => {
        totalPage.value = Math.ceil(totalCount / pageSize.value);
    };

    const setPageNumber = (target: number): void => {
        pageNumber.value = target;
    };

    const setFirstPageNumber = (): void => {
        pageNumber.value = 1;
    };

    return {
        totalPage,
        pageSize,
        pageNumber,
        setTotalPage,
        setPageNumber,
        setFirstPageNumber,
    };
};
