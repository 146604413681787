import { inject, provide, Ref, ref } from '@vue/composition-api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideAnalysisProgressListProgressCircular = () => {
    const isAnalysisProgressListProgressing = ref(false);

    provide('isAnalysisProgressListProgressing', isAnalysisProgressListProgressing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useAnalysisProgressListProgressCircular = () => {
    const isAnalysisProgressListProgressing = inject('isAnalysisProgressListProgressing') as Ref<boolean>;

    const analysisProgressListProgressStart = (): void => {
        isAnalysisProgressListProgressing.value = true;
    };

    const analysisProgressListProgressEnd = (): void => {
        isAnalysisProgressListProgressing.value = false;
    };

    return {
        isAnalysisProgressListProgressing,
        analysisProgressListProgressStart,
        analysisProgressListProgressEnd,
    };
};
